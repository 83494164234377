import { Component, OnInit } from "@angular/core";
import { RestService } from "src/app/services/rest.service";
import { UntypedFormGroup, Validators, UntypedFormControl } from "@angular/forms";
import { StateService } from "src/app/services/state.service";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  constructor(
    private _rest: RestService,
    public _state: StateService,
    private _router: Router,
    private _spinner: NgxSpinnerService,
    private _toastr: ToastrService
  ) {}

  ngOnInit() {
    if (localStorage.getItem("token")) {
    }

    this.loginForm = new UntypedFormGroup({
      username: new UntypedFormControl("", [Validators.required]),
      password: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(4)
      ])
    });
  }

  login() {
    console.log("login");
    this._spinner.show();
    if (this.loginForm.valid) {
      this._rest.login(this.loginForm.value).subscribe(
        data => {
          console.log(data);
          this._spinner.hide();
          this._state.token = data["RESULT"]["token"];
          localStorage.setItem("token", this._state.token);
          localStorage.setItem("username", this.loginForm.value.username);
          localStorage.setItem("mqtt_token", data["RESULT"]["token"]);
          this._toastr.success("Welcome");
          location.reload();
          this._router.navigate(["/app"]); 
        },
        error => {
          this._spinner.hide();
          this._toastr.warning("Unable to login", "Error");
        }
      );
    }
  }
}
