import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { PrivateGuard } from "./guards/private.guard";
import { LoginComponent } from "./pages/extra/login/login.component";
import { PublicGuard } from "./guards/public.guard";
import { ApplicationComponent } from "./pages/application/application.component";
import { DeviceComponent } from "./pages/device/device.component";
import { AddDeviceComponent } from "./pages/device/add-device/add-device.component";
import { UserManagerComponent } from "./pages/user-manager/user-manager.component";

const routes: Routes = [
  { path: "", redirectTo: "app", pathMatch: "full" },
  { path: "login", component: LoginComponent, canActivate: [PublicGuard] },
  {
    path: "app",
    component: ApplicationComponent,
    canActivate: [PrivateGuard],
    children: [
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [PrivateGuard],
      },
      {
        path: "users",
        component: UserManagerComponent,
        canActivate: [PrivateGuard],
      },
      {
        path: "device/:id",
        component: DeviceComponent,
        canActivate: [PrivateGuard],
      },
      // {
      //   path: "addDevice",
      //   component: AddDeviceComponent,
      //   canActivate: [PrivateGuard],
      // },
    ],
  },
  { path: "**", redirectTo: "login", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
