import { Component } from '@angular/core';
import { StateService } from './services/state.service';
import { RestService } from './services/rest.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(public _state: StateService, private _rest: RestService, private _router: Router,
  ) {
    // this._rest.get_user_details({}).subscribe(data => {
    //   this._state.user = data['RESULT'];
    //   this._state.user_name = data['RESULT']['f_name'] + ' ' + data['RESULT']['l_name'];
    // }, error => {
    //   this._router.navigate(['/logout']);
    // }
    // );

  }
}
