import { Injectable } from "@angular/core";
// import { JwtHelperService } from "@auth0/angular-jwt";
// const helper = new JwtHelperService();
import { jwtDecode } from "jwt-decode";
import { DeviceData } from "../models/get-device.interface";

@Injectable()
export class StateService {
  objectKeys = Object.keys;
  mqtt_conn = {};

  socket;
  user;
  org_name = "GreenTriz Pvt. Ltd.";
  user_name = "user";
  token;
  decodedToken;
  devices_list = [] as DeviceData[];
  devices = {};
  device_config = {};
  device_analog_limit = {};
  schedule = [];

  constructor() {
    if (!this.token && localStorage.getItem("token")) {
      this.token = localStorage.getItem("token");
      // this.decodedToken = helper.decodeToken(this.token);
      this.decodedToken = jwtDecode(this.token);
      console.log(this.decodedToken);
    }
  }

  isLogin(): boolean {
    if (this.token) {
      return true;
    } else {
      return false;
    }
  }

  get_date(epoch) {
    const date = new Date(epoch * 1000);
    return (
      date.getUTCDate() +
      "-" +
      (date.getUTCMonth() + 1) +
      "-" +
      date.getUTCFullYear()
    );
  }
}
