import { Component, OnInit } from "@angular/core";
import { StateService } from "src/app/services/state.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"]
})
export class NavbarComponent implements OnInit {
  constructor(public _state: StateService, public _router: Router) {}

  ngOnInit() {}

  logout() {
    localStorage.removeItem("id");
    localStorage.removeItem("token");
    this._state.token = undefined;
    this._state.devices_list = [];
    this._state.devices = {};
    this._router.navigate(["/login"]);
  }
}
