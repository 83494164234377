import { Component, OnInit } from "@angular/core";
import { MqttService } from "src/app/services/mqtt.service";

@Component({
	selector: "app-application",
	templateUrl: "./application.component.html",
	styleUrls: ["./application.component.css"],
})
export class ApplicationComponent implements OnInit {
	constructor(private _mqtt: MqttService) {
		// console.log("app constrator");
	}

	ngOnInit() {
		// console.log("app init");
		this._mqtt.start_connction();
	}
}
