import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { StateService } from "./state.service";
import { DeviceResponses } from "../models/get-device.interface";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RestService {
  constructor(private _http: HttpClient, private _state: StateService) {}

  public x(data) {
    const httpOptions = {
      headers: new HttpHeaders({ token: localStorage.getItem("token") }),
    };
    return this._http.post(environment.url, data, httpOptions);
  }

  public login(data) {
    const url = environment.url;
    const httpOptions = {
      headers: new HttpHeaders(),
    };
    data._type = "login";
    return this._http.post(url, data, httpOptions);
  }

  public getDevices(): Observable<DeviceResponses> {
    const url = environment.url;
    const body = {"_type": "getDevices", "_token": this._state.token};
    return this._http.post<DeviceResponses>(url, body);
  }

  public addDevice(data) {
    const url = environment.url + "/adddevice";
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: this._state.token }),
    };
    return this._http.post(url, data, httpOptions);
  }

  public deleteDevice(data) {
    const url = environment.url + "/deletedevice";
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: this._state.token }),
    };
    return this._http.post(url, data, httpOptions);
  }

  public updateDeviceDetails(data) {
    const url = environment.url + "/updatedevicedetails";
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: this._state.token }),
    };
    return this._http.post(url, data, httpOptions);
  }

  public getRunLogs(id, from, to) {
    const url = environment.url + "/getRunLogs";
    const data = { id: id, from: from, to: to };
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: this._state.token }),
    };
    return this._http.post(url, data, httpOptions);
  }
  
  public getReports(id, from, to) {
    const url = environment.url + "/getReports";
    const data = { id: id, from: from, to: to };
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: this._state.token }),
    };
    return this._http.post(url, data, httpOptions);
  }

  public getMonthReports(id, from, to) {
    const url = environment.url;
    const data = { id: id, from: from, to: to, _type: "getMonthReports", _token: this._state.token };
    return this._http.post(url, data);
  }

  //admin
  public getUsers() {
    const url = environment.url + "/getusers";
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: this._state.token }),
    };
    return this._http.get(url, httpOptions);
  }

  public getuserdevices(id) {
    const url = environment.url + "/getuserdevices";
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: this._state.token }),
    };
    return this._http.post(url, { id: id }, httpOptions);
  }

  public updatedevicevalidity(uuid, date) {
    const url = environment.url + "/updatedevicevalidity";
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: this._state.token }),
    };
    return this._http.post(url, { uuid: uuid, date: date }, httpOptions);
  }

  public adduser(data) {
    const url = environment.url + "/adduser";
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: this._state.token }),
    };
    return this._http.post(url, data, httpOptions);
  }

  public updatepassword(data) {
    const url = environment.url + "/updateuserpassword";
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: this._state.token }),
    };
    return this._http.post(url, data, httpOptions);
  }
}
