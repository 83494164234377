import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  year;
  constructor(public _state: StateService) { }

  ngOnInit() {
    this.year = (new Date()).getFullYear();

  }

}
