import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppComponent } from "./app.component";
import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxGaugeModule } from "ngx-gauge";
import { FullCalendarModule } from "@fullcalendar/angular"; // for FullCalendar!
// import { AgmCoreModule } from "@agm/core";

import { StateService } from "./services/state.service";
import { NavbarComponent } from "./layout/navbar/navbar.component";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { LoginComponent } from "./pages/extra/login/login.component";
import { ApplicationComponent } from "./pages/application/application.component";
import { DeviceComponent } from "./pages/device/device.component";
// import { AddDeviceComponent } from "./pages/device/add-device/add-device.component";
import { UserManagerComponent } from "./pages/user-manager/user-manager.component";

@NgModule({
	declarations: [
		AppComponent,
		NavbarComponent,
		SidebarComponent,
		FooterComponent,
		DashboardComponent,
		LoginComponent,
		ApplicationComponent,
		DeviceComponent,
		// AddDeviceComponent,
		UserManagerComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		NgxSpinnerModule,
		NgxGaugeModule,
		// FullCalendarModule, // for FullCalendar!
		ToastrModule.forRoot(), // ToastrModule added
		// AgmCoreModule.forRoot(),
	],
	providers: [StateService],
	bootstrap: [AppComponent],
})
export class AppModule {}
