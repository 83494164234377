import { Component, OnInit } from "@angular/core";
import { StateService } from "src/app/services/state.service";
import { Router } from "@angular/router";
import { NgIf } from "@angular/common";
import { RestService } from "src/app/services/rest.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-user-manager",
  templateUrl: "./user-manager.component.html",
  styleUrls: ["./user-manager.component.css"],
})
export class UserManagerComponent implements OnInit {
  users = [];
  username = "";
  password = "";

  devices = [];

  constructor(
    private _rest: RestService,
    public _state: StateService,
    private _router: Router,
    private _spinner: NgxSpinnerService,
    private _toastr: ToastrService
  ) { }

  ngOnInit() {
    if (this._state.decodedToken.id !== 1) {
      this._router.navigate(["/login"]);
    } else {
      this.fetchUsers();
    }
  }

  fetchUsers() {
    this.users = [];
    this._spinner.show();
    this._rest.getUsers().subscribe(
      (users) => {
        this._spinner.hide();
        users["data"].forEach((user) => {
          this.users.push(user);
        });
      },
      (err) => {
        this._spinner.hide();
        this._toastr.error("Error to fetch users.");
      }
    );
  }

  adduser() {
    console.log(this.username, this.password);
    if (this.username !== "" && this.password !== "") {
      this._rest
        .adduser({ username: this.username, password: this.password })
        .subscribe(
          (users) => {
            this._spinner.hide();
            this._toastr.success(this.username + " User added.");
            this.username = "";
            this.password = "";
            this.fetchUsers();
          },
          (err) => {
            this._spinner.hide();
            this._toastr.error("Error to add user.");
          }
        );
    }
  }

  updatepassword(index) {
    console.log(this.users[index]);
    this._spinner.show();
    this._rest.updatepassword(this.users[index]).subscribe(
      (users) => {
        this._spinner.hide();
        this._toastr.success("update password success.");
      },
      (err) => {
        this._spinner.hide();
        this._toastr.error("Error to update password.");
      }
    );
  }

  updatedevicevalidity(index) {
    let date = this.devices[index]["exp"] + " 23:59:59.999999";
    console.log(this.devices[index]);
    this._spinner.show();
    this._rest
      .updatedevicevalidity(this.devices[index]["uuid"], date)
      .subscribe(
        (users) => {
          this._spinner.hide();
          this._toastr.success("update success.");
        },
        (err) => {
          this._spinner.hide();
          this._toastr.error("Error to update.");
        }
      );
  }
  deleteDevice(i) {
    if (confirm("Are you sure?")) {
      this._rest
        .deleteDevice({ id: this.devices[i]["id"] })
        .subscribe(
          (users) => {
            this._spinner.hide();
            this._toastr.success("Delete success.");
          },
          (err) => {
            this._spinner.hide();
            this._toastr.error("Error to Delete.");
          }
        );
    }
  }

  getuserdevices(index) {
    this.devices = [];
    console.log(this.users[index]);
    this._spinner.show();
    this._rest.getuserdevices(this.users[index]["id"]).subscribe(
      (device) => {
        this._spinner.hide();
        device["RESULT"].forEach((device) => {
          device.exp = new Date(device["exp_date"]).toISOString().split("T")[0];
          this.devices.push(device);
        });
      },
      (err) => {
        this._spinner.hide();
        this._toastr.error("Error to Fetch Devices.");
      }
    );
  }
}
