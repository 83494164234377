import { Injectable } from "@angular/core";
// import * as mqtt from "mqtt";
import { StateService } from "./state.service";
import { RestService } from "./rest.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
// var crc16 = require("js-crc").crc16;

@Injectable({
  providedIn: "root",
})
export class MqttService {
  private url = environment.mqtt_url;
//   private socket;
  connected = false;
  connectedList = {};
  lpm_timer = {};

  constructor(
    private _rest: RestService,
    private _state: StateService,
    private _tostr: ToastrService,
    private _spinner: NgxSpinnerService,
    private _router: Router
  ) {
    let username = localStorage.getItem("username");
    let password = localStorage.getItem("token");
    // this.socket = mqtt.connect(this.url, {
    //   username: username,
    //   password: password,
    // });

    // this.socket.on("connect", () => {
    //   this._tostr.success("Connected to server");
    //   this.connected = true;
    // });
  }

  start_connction() {
    Object.keys(this._state.devices).forEach((key) => {
    //   this.remove_conn(key);
    });
    this._rest.getDevices().subscribe(
      (data) => {
        // console.log(data);
        this._state.devices_list = [];
        if (data["RESULT"].length) {
          let list = data["RESULT"];
          Object.keys(data["RESULT"]).forEach((key) => {
            // console.log({key})
            this._state.devices_list.push(list[key]);
            // this.add_conn(list[key]);
            // console.log("device list", this._state.devices_list,"second", list);

          });
        }
      },
      (err) => {
        // console.log(err.status);
        if (err.status === 401) {
          localStorage.removeItem("id");
          localStorage.removeItem("token");
          this._state.token = undefined;
          this._state.devices_list = [];
          this._state.devices = {};
          this._router.navigate(["/login"]);
        }
      }
    );
  }

//   add_conn(device) {
//     let id = device["id"];
//     let uuid = device["uuid"];
//     this.socket.subscribe("vidani/fm/" + uuid + "/data", (err) => {
//       this.socket.subscribe("vidani/fm/" + uuid + "/notify", (err) => { });
//       // this.socket.subscribe("vidani/fm/" + uuid + "/pulse", (err) => { });
//       if (!err) {
//         // console.log("connected with id: " + id);
//         this.socket.publish(
//           "vidani/fm/" + uuid,
//           JSON.stringify({ type: "command", id: 1, cmd: "get_data" })
//         );
//       } else {
//         console.log(err);
//         this._tostr.warning("Connection with server is disturbed, Retrying...");
//       }
//       this._state.devices[id] = device;
//       this._state.devices[id]["connected"] = 0;
//       this._state.devices[id]["flt"] = "";
//       this._state.devices[id]["flow"] = 0;
//       this._state.devices[id]["old_flow"] = 0;
//       this._state.devices[id]["lpm"] = 0;
//       this._state.devices[id]["v1"] = 0;
//       this._state.devices[id]["v2"] = 0;
//       this._state.devices[id]["v3"] = 0;
//       this._state.devices[id]["c1"] = 0;
//       this._state.devices[id]["c2"] = 0;
//       this._state.devices[id]["c3"] = 0;
//       this._state.devices[id]["s1"] = 0;
//       this._state.devices[id]["s2"] = 0;
//       this._state.devices[id]["s3"] = 0;
//       this._state.devices[id]["vf1"] = 0;
//       this._state.devices[id]["vf2"] = 0;
//       this._state.devices[id]["vf3"] = 0;
//       this._state.devices[id]["cf1"] = 0;
//       this._state.devices[id]["cf2"] = 0;
//       this._state.devices[id]["cf3"] = 0;
//       this._state.devices[id]["current_run"] = {};
//     });
//     if (!this.lpm_timer[uuid]) {
//       this.lpm_timer[uuid] = setInterval(() => {
//         if (this._state.devices[id]["old_flow"] !== 0) {
//           this._state.devices[id]["lpm"] = this._state.devices[id]["flow"] - this._state.devices[id]["old_flow"];
//         }
//         console.log(this._state.devices[id]["lpm"], this._state.devices[id]["flow"], this._state.devices[id]["old_flow"]);
//         this._state.devices[id]["old_flow"] = this._state.devices[id]["flow"];
//         if (this._state.devices[id]["current_run"]['end_time']) {
//           this._state.devices[id]["current_run"] = {};
//         }
//       }, 1000 * 60);
//     }
//     this.socket.subscribe("vidani/fm/" + uuid + "/config", (err) => {
//       if (err) {
//         console.log(err);
//         this._tostr.warning("Connection with server is disturbed, Retrying...");
//       }
//     });

//     this.socket.on("message", (topic, payload) => {
//       this._spinner.hide();
//       // console.log(payload.toString());
//       try {
//         if (Number(uuid) === Number(topic.split("/")[2])) {
//           let data = JSON.parse(payload);
//           // let id = topic.split("/")[2];
//           // let id = this._state.devices[topic.split("/")[2]]["id"];
//           let topicName = topic.split("/")[3];
//           // console.log(id, topicName);
//           // console.log(id, data["devices"][0]);
//           if (topicName === "pulse") {
//             console.log('Pulse', data);

//             this._state.devices[id]["current_run"] = data;
//           }
//           if (topicName === "data" || topicName === "notify") {
//             if (this.connectedList[id]) clearTimeout(this.connectedList[id]);
//             this.connectedList[id] = setTimeout(() => {
//               this._state.devices[id]["connected"] = 0;
//             }, 1000 * 60 * 3);
//             this._state.devices[id]["connected"] = 1;
//             // this._state.devices[id]["v1"] = data["devices"][0]["ai"][0];
//             // this._state.devices[id]["v2"] = data["devices"][0]["ai"][1];
//             // this._state.devices[id]["v3"] = data["devices"][0]["ai"][2];
//             // this._state.devices[id]["c1"] = data["devices"][0]["ai"][3];
//             // this._state.devices[id]["c2"] = data["devices"][0]["ai"][4];
//             // this._state.devices[id]["c3"] = data["devices"][0]["ai"][5];
//             // this._state.devices[id]["lpm"] = (Number(data["devices"][0]["ai"][0])/10) * Number(this._state.devices[id]["flow_multiplier"]);
//             if (this._state.devices[id]['type'] === 3) {
//               this._state.devices[id]["lpm"] = (Number(data.lpm));
//               this._state.devices[id]["flow"] = (Number(data.total));
//             } else if (this._state.devices[id]['type'] === 1) {
//               this._state.devices[id]["flow"] = (Number(data["devices"][0]["ai"][1]) * 65536) + Number(data["devices"][0]["ai"][2]);
//             } else if (this._state.devices[id]['type'] === 2) {
//               this._state.devices[id]["flow"] = (Number(data["devices"][0]["ai"][8]) * 65536) + Number(data["devices"][0]["ai"][9]);
//             }
//             // this._state.devices[id]["s1"] = data["devices"][0]["do"][0];
//             // this._state.devices[id]["s2"] = data["devices"][0]["do"][0];
//             // this._state.devices[id]["s3"] = data["devices"][0]["do"][0];
//             // this._state.devices[id]["s1"] = data["devices"][0]["ai"][3] > 1 ? 1 : 0;
//             // this._state.devices[id]["s2"] = data["devices"][0]["ai"][4] > 1 ? 1 : 0;
//             // this._state.devices[id]["s3"] = data["devices"][0]["ai"][5] > 1 ? 1 : 0;
//             // this._state.devices[id]["vf1"] = data["devices"][0]["flt"][0];
//             // this._state.devices[id]["vf2"] = data["devices"][0]["flt"][1];
//             // this._state.devices[id]["vf3"] = data["devices"][0]["flt"][2];
//             // this._state.devices[id]["cf1"] = data["devices"][0]["flt"][3];
//             // this._state.devices[id]["cf2"] = data["devices"][0]["flt"][4];
//             // this._state.devices[id]["cf3"] = data["devices"][0]["flt"][5];
//             // if (this._state.devices[id]["old_flow"] === 0) {
//             // this._state.devices[id]["old_flow"] = this._state.devices[id]["flow"];
//             // }
//             // this._state.devices[id]["power"] = (this._state.devices[id]['v1'] * this._state.devices[id]['c1']);

//             // if (this._state.devices[id]["flow_multiplier"] ) {
//             // this._state.devices[id]["lpm"] = this._state.devices[id]["power"] * this._state.devices[id]["flow_multiplier"];
//             // }
//             console.log(data["devices"][0]);
//             // console.log(this._state.devices);
//             if (topicName === "notify") {
//               this._tostr.error("Warning in device id " + uuid);
//               console.log(this._state.devices);
//             }
//           } else if (topicName === "config") {
//             if (data["type"] === "time") {
//               console.log(id, topicName, data);
//               this._state.schedule[id] = [];
//               let time_list = data["value"];
//               time_list.forEach((time_string) => {
//                 let status = time_string.split(",")[0] === "1" ? true : false;
//                 let from =
//                   (
//                     "0" +
//                     (Number(time_string.split(",")[1]) / 60)
//                       .toString()
//                       .split(".")[0]
//                   ).slice(-2) +
//                   ":" +
//                   ("0" + (Number(time_string.split(",")[1]) % 60)).slice(-2);
//                 let to =
//                   (
//                     "0" +
//                     (Number(time_string.split(",")[2]) / 60)
//                       .toString()
//                       .split(".")[0]
//                   ).slice(-2) +
//                   ":" +
//                   ("0" + (Number(time_string.split(",")[2]) % 60)).slice(-2);
//                 this._state.schedule[id].push([status, from, to]);
//               });
//             } else if (data["type"] === "config") {
//               console.log(id, data);
//               this._state.device_config[id] = [];
//               Object.keys(data).forEach((key) => {
//                 this._state.device_config[id].push({
//                   key: key,
//                   value: data[key],
//                 });
//                 if (key === "analog_limit") {
//                   this._state.device_analog_limit[id] = [];
//                   data[key].forEach((element) => {
//                     this._state.device_analog_limit[id].push(
//                       element.split(",")
//                     );
//                   });
//                 }
//               });
//             }
//           }
//         }
//       } catch (e) {
//         this._tostr.error(
//           "error to get " + topic.split("/")[3] + " of " + topic.split("/")[2]
//         );
//         console.error(topic, payload.toString(), e);
//       }
//     });
//   }

//   remove_conn(device) {
//     let id = device["id"];
//     let uuid = device["uuid"];
//     this.socket.unsubscribe("vidani/fm/" + uuid + "/data");
//     this.socket.unsubscribe("vidani/fm/" + uuid + "/config");
//     delete this._state.devices[id];
//   }

  get_data(id) {
    let uuid = this._state.devices[id]["uuid"];
    this._spinner.show();
    setTimeout(() => {
      this._spinner.hide();
    }, 5000);
    // this.socket.publish(
    //   "vidani/fm/" + uuid,
    //   JSON.stringify({ type: "command", id: 1, cmd: "get_data" })
    // );
  }

//   toggle_switch(id, switch_id, value) {
//     let uuid = this._state.devices[id]["uuid"];
//     this.socket.publish(
//       "vidani/fm/" + uuid,
//       JSON.stringify({
//         type: "control",
//         id: 1,
//         key: switch_id,
//         value: value,
//       }),
//       [],
//       (err) => {
//         this._spinner.hide();
//         if (err)
//           this._tostr.error("Try again", "Error in communication", {
//             timeOut: 500,
//           });
//         // this._tostr.success("please wait", "Command sent", { timeOut: 500 });
//       }
//     );
//   }

//   get_schedule(id) {
//     let uuid = this._state.devices[id]["uuid"];
//     this.socket.publish(
//       "vidani/fm/" + uuid,
//       JSON.stringify({
//         type: "command",
//         id: 1,
//         cmd: "get_time",
//       }),
//       [],
//       (err) => {
//         if (err)
//           this._tostr.error("Try again", "Error in communication", {
//             timeOut: 500,
//           });
//         // this._tostr.success("please wait", "Command sent", { timeOut: 500 });
//       }
//     );
//   }

//   get_config(id) {
//     let uuid = this._state.devices[id]["uuid"];
//     this.socket.publish(
//       "vidani/fm/" + uuid,
//       JSON.stringify({
//         type: "command",
//         id: 1,
//         cmd: "get_config",
//       }),
//       [],
//       (err) => {
//         if (err)
//           this._tostr.error("Try again", "Error in communication", {
//             timeOut: 500,
//           });
//         // this._tostr.success("please wait", "Command sent", { timeOut: 500 });
//       }
//     );
//   }

  update_schedule(id, month) {
    let uuid = this._state.devices[id]["uuid"];
    console.log(id, month, this._state.schedule[id]);
    let timelist = [
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
      "0000",
    ];
    let count = 0;
    this._state.schedule[id].forEach((slot) => {
      let time =
        Number(slot.start.split("T")[1].split(":")[0] * 60) +
        Number(slot.start.split("T")[1].split(":")[1]);
      timelist[count] = ("0000" + time).slice(-4);
      count++;
      let time1 =
        Number(slot.end.split("T")[1].split(":")[0] * 60) +
        Number(slot.end.split("T")[1].split(":")[1]);
      timelist[count] = ("0000" + time1).slice(-4);
      count++;
      console.log(count);
    });
    console.log(timelist);
    // this.socket.publish(
    //   "vidani/fm/" + uuid,
    //   JSON.stringify({
    //     type: "time",
    //     id: 1,
    //     key: ("0" + month).slice(-2),
    //     value: timelist,
    //   }),
    //   [],
    //   (err) => {
    //     if (err)
    //       this._tostr.error("Try again", "Error in communication", {
    //         timeOut: 500,
    //       });
    //     // this._tostr.success("please wait", "Command sent", { timeOut: 500 });
    //   }
    // );
  }

//   update_config(id, key, value) {
//     let count = 0;
//     let uuid = this._state.devices[id]["uuid"];
//     this.socket.publish(
//       "vidani/fm/" + uuid,
//       JSON.stringify({
//         type: "config",
//         id: 1,
//         key: key,
//         value: value,
//       }),
//       [],
//       (err) => {
//         if (err)
//           this._tostr.error("Try again", "Error in communication", {
//             timeOut: 500,
//           });
//         // this._tostr.success("please wait", "Command sent", { timeOut: 500 });
//       }
//     );
//   }

  tConv24(time24) {
    let ts = time24;
    let H = +ts.substr(0, 2);
    let h = H % 12 || 12;
    h = Number(h < 10 ? "0" + h : h); // leading 0 at the left for 1 digit hours
    let ampm = H < 12 ? "a" : "p";
    ts = h + ts.substr(2, 3) + ampm;
    return " to " + ts;
  }
}
